import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

import { Paragraph } from '../styles.jsx';

const DetailsWrapper = styled.section`
  background-color: ${(props) => props.theme.cardBackground};
`;

const DetailsTrigger = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 1rem;
  border: 0;
  border-top: 1px solid ${(props) => props.theme.borders};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: left;
  background-color: ${(props) => props.theme.cardBackground};
  color: ${(props) => props.theme.contrast};
  cursor: pointer;
`;

const DetailsContent = styled.div`
  padding: 0 1rem 1rem;
`;

const DetailsParagraph = styled(Paragraph)`
  color: ${(props) => props.theme.contrast};
  line-height: 155%;
`;

const DetailsIndicationIcon = styled(ChevronDown)`
  transform: ${(props) => (props.open ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transition: all 0.225s;
`;

function Details({ url, title, content }) {
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <DetailsWrapper>
      <DetailsTrigger
        onClick={() => setOpenDetails(!openDetails)}
        aria-expanded={openDetails}
        aria-controls={url}
      >
        {title} <DetailsIndicationIcon open={openDetails} />
      </DetailsTrigger>
      {openDetails && (
        <DetailsContent url={url}>
          <DetailsParagraph>{content}</DetailsParagraph>
        </DetailsContent>
      )}
    </DetailsWrapper>
  );
}

Details.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
};

export default Details;
