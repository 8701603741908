import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import { DarkTheme } from './theme/colors';
import { LightTheme } from './theme/colors';
import './theme/videoplayer.css';

import Player from './components/video-player.jsx';

export function VideoPlayer({ url, theme, ...rest }) {
  const [darkTheme, setDarkTheme] = useState(true);

  return (
    <ThemeProvider theme={theme ? theme : darkTheme ? DarkTheme : LightTheme}>
      <Player handleTheme={setDarkTheme} url={url} {...rest} />
    </ThemeProvider>
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  theme: PropTypes.object,
};
