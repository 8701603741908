const contrast = '#FFF';
const lightGrey = '#95A1A6';
const dark = '#000';

export const DarkTheme = {
  //name
  name: 'DarkTheme',

  //shades
  primary: '#0CAFFA',
  primaryHover: '#0BA1E5',
  background: '#09151A',
  cardBackground: '#293033',
  transparent: 'transparent',
  borders: '#3E484D',
  inputBorders: '#95A1A6',
  succes: '#1CC22D',
  warning: '#FDB12B',
  danger: '#FF2B2B',
  dangerHover: '#ED0000',
  light: '#fff',

  //contrasts
  contrast: contrast,
  contrastOpac15: 'rgba(255, 255, 255, 0.15)',
  contrastOpac5: 'rgba(255, 255, 255, 0.05)',
  contrastMedium: lightGrey,
  contrastLow: dark,
  contrastLowOpac55: 'rgba(0, 0, 0, 0.55)',

  //text
  primaryTextColor: contrast,
  secondaryTextColor: lightGrey,
  contrastTextColor: dark,
  dangerTextColor: '#ED0000',
  succesTextColor: '#00A307',
};

export const LightTheme = {
  name: 'LightTheme',

  //shades
  primary: '#0CAFFA',
  primaryHover: '#0BA1E5',
  background: '#09151A',
  cardBackground: '#F1F3F4',
  transparent: 'transparent',
  borders: '#3E484D',
  inputBorders: '#95A1A6',
  succes: '#1CC22D',
  warning: '#FDB12B',
  danger: '#FF2B2B',
  dangerHover: '#ED0000',
  light: '#fff',

  //contrasts
  contrast: dark,
  contrastOpac15: 'rgba(0, 0, 0, 0.15)',
  contrastOpac5: 'rgba(0, 0, 0, 0.05)',
  contrastMedium: lightGrey,
  contrastLow: contrast,
  contrastLowOpac55: 'rgba(0, 0, 0, 0.55)',

  //text
  primaryTextColor: contrast,
  secondaryTextColor: lightGrey,
  contrastTextColor: dark,
  dangerTextColor: '#ED0000',
  succesTextColor: '#00A307',
};
