import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip/dist/index.js';

export const Paragraph = styled.p`
  margin: 0;
  color: ${(props) => props.theme.secondaryTextColor};
  font-size: 0.875rem;
  line-height: 145%;
`;

export const Span = styled.span`
  margin: 0;
  color: ${(props) => props.theme.secondaryTextColor};
  font-size: 0.875rem;
  line-height: 145%;
`;

export const VideoProgressContainer = styled.section`
  background-color: ${(props) => props.theme.cardBackground};
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
`;

export const VideoProgress = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VideoTime = styled(Span)`
  margin: 0.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.contrast};
`;

export const Video = styled.video`
  width: 100%;
  display: block;
  object-fit: cover;
`;

export const VideoButton = styled.button`
  position: relative;
  color: ${(props) => props.theme.contrast};
  background: none;
  border: 0;
  border-bottom: ${(props) => (props.active ? 'solid' : 'none')} 0.25rem;
  padding: 0.5rem;
  margin: 2px;
  height: 40px;
  width: 40px;
  border-radius: 0.5rem;
  border-color: ${(props) => (props.active ? props.theme.contrast : 'none')};
  border-radius: 0;
  transition: 0.125s;
  &:hover {
    cursor: pointer;
    transform: scale(1.25);
  }
`;

export const ReactTooltipStyled = styled(ReactTooltip)`
  span {
    margin: 0;
    padding: 0;
    font-size: 0.5rem;
  }
  &.__react_component_tooltip {
    padding: 0 0.125rem 0.125rem;
  }
`;

export const VideoContainer = styled.section`
  padding: 0;
  font-family: 'Inter';
  color: white;
  margin: auto;
  width: 100%;
  * {
    outline: 2px solid transparent;
    outline-offset: 5px;
    box-sizing: border-box;
  }
  *:focus {
    outline: 2px solid #e8c822;
    outline-offset: 5px;
  }
  p: {
    font-size: 0.75rem;
  }
`;

export const Svg = styled.svg`
  display: block;
`;

export const VideoWrapper = styled.div`
  position: relative;
`;

export const ControlsContainer = styled.section`
  background-color: ${(props) => props.theme.cardBackground};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
`;

export const ScreenReader = styled.span`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const animateOpacity = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
  
`;

export const PlayPause = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  animation: ${animateOpacity} 0.45s linear forwards;
  background: ${(props) => props.theme.contrastLowOpac55};
`;

export function Logo({ width }) {
  return (
    <Svg
      width={width}
      viewBox="0 0 372 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.4194 21.9462V25.8172H62V21.9462H72.4194ZM64.1828 17.9892H69.4409V33.2688C69.4409 33.5914 69.491 33.853 69.5914 34.0538C69.6989 34.2473 69.853 34.3871 70.0538 34.4731C70.2545 34.552 70.4946 34.5914 70.7742 34.5914C70.9749 34.5914 71.1864 34.5735 71.4086 34.5376C71.638 34.4946 71.81 34.4588 71.9247 34.4301L72.7204 38.2258C72.4695 38.2975 72.1147 38.3871 71.6559 38.4946C71.2043 38.6022 70.6631 38.6703 70.0323 38.6989C68.7993 38.7563 67.7419 38.6129 66.8602 38.2688C65.9857 37.9176 65.3154 37.3728 64.8495 36.6344C64.3907 35.8961 64.1685 34.9677 64.1828 33.8495V17.9892Z"
        fill="white"
      />
      <path
        d="M81.1204 38.7742C79.3857 38.7742 77.8946 38.4194 76.6473 37.7097C75.4072 36.9928 74.4502 35.9964 73.7763 34.7204C73.1097 33.4373 72.7763 31.9498 72.7763 30.2581C72.7763 28.5591 73.1097 27.0717 73.7763 25.7957C74.4502 24.5125 75.4072 23.5161 76.6473 22.8065C77.8946 22.0896 79.3857 21.7312 81.1204 21.7312C82.8552 21.7312 84.3427 22.0896 85.5828 22.8065C86.8301 23.5161 87.7871 24.5125 88.4538 25.7957C89.1276 27.0717 89.4645 28.5591 89.4645 30.2581C89.4645 31.9498 89.1276 33.4373 88.4538 34.7204C87.7871 35.9964 86.8301 36.9928 85.5828 37.7097C84.3427 38.4194 82.8552 38.7742 81.1204 38.7742ZM81.1527 34.8064C81.7835 34.8064 82.3176 34.6129 82.7548 34.2258C83.1921 33.8387 83.5254 33.3011 83.7548 32.6129C83.9914 31.9247 84.1097 31.129 84.1097 30.2258C84.1097 29.3082 83.9914 28.5054 83.7548 27.8172C83.5254 27.129 83.1921 26.5914 82.7548 26.2043C82.3176 25.8172 81.7835 25.6237 81.1527 25.6237C80.5004 25.6237 79.9484 25.8172 79.4968 26.2043C79.0523 26.5914 78.7118 27.129 78.4753 27.8172C78.2459 28.5054 78.1312 29.3082 78.1312 30.2258C78.1312 31.129 78.2459 31.9247 78.4753 32.6129C78.7118 33.3011 79.0523 33.8387 79.4968 34.2258C79.9484 34.6129 80.5004 34.8064 81.1527 34.8064Z"
        fill="white"
      />
      <path
        d="M98.3645 38.7742C96.6369 38.7742 95.1459 38.4337 93.8914 37.7527C92.6441 37.0645 91.6835 36.086 91.0097 34.8172C90.343 33.5412 90.0097 32.0251 90.0097 30.2688C90.0097 28.5627 90.3466 27.0717 91.0204 25.7957C91.6943 24.5125 92.6441 23.5161 93.8699 22.8065C95.0957 22.0896 96.5401 21.7312 98.2032 21.7312C99.3789 21.7312 100.454 21.914 101.429 22.2796C102.404 22.6452 103.246 23.1864 103.956 23.9032C104.666 24.6201 105.218 25.5054 105.612 26.5591C106.006 27.6057 106.203 28.8065 106.203 30.1613V31.4731H91.8484V28.4194H101.311C101.304 27.8602 101.171 27.362 100.913 26.9247C100.655 26.4875 100.3 26.147 99.8484 25.9032C99.4039 25.6523 98.8914 25.5269 98.3108 25.5269C97.7229 25.5269 97.1961 25.6595 96.7301 25.9247C96.2642 26.1828 95.895 26.5376 95.6226 26.9892C95.3502 27.4337 95.2068 27.9391 95.1925 28.5054V31.6129C95.1925 32.2867 95.3251 32.8781 95.5903 33.3871C95.8556 33.8889 96.2319 34.2796 96.7194 34.5591C97.2068 34.8387 97.7875 34.9785 98.4613 34.9785C98.9272 34.9785 99.3502 34.914 99.7301 34.7849C100.11 34.6559 100.436 34.4659 100.709 34.2151C100.981 33.9642 101.185 33.6559 101.322 33.2903L106.149 33.4301C105.949 34.5125 105.508 35.4552 104.827 36.2581C104.153 37.0538 103.268 37.6738 102.171 38.1183C101.074 38.5556 99.8054 38.7742 98.3645 38.7742Z"
        fill="white"
      />
      <path
        d="M115.074 45C113.504 45 112.16 44.7814 111.041 44.3441C109.923 43.9068 109.045 43.3082 108.407 42.5484C107.769 41.7885 107.385 40.9283 107.256 39.9677L112.235 39.6882C112.328 40.0251 112.5 40.319 112.751 40.5699C113.009 40.8208 113.342 41.0143 113.751 41.1505C114.167 41.2867 114.654 41.3548 115.213 41.3548C116.095 41.3548 116.823 41.1398 117.396 40.7097C117.977 40.2867 118.267 39.5448 118.267 38.4839V35.5806H118.084C117.877 36.0896 117.561 36.5448 117.138 36.9462C116.715 37.3477 116.188 37.6631 115.558 37.8925C114.934 38.1219 114.217 38.2366 113.407 38.2366C112.203 38.2366 111.099 37.957 110.095 37.3979C109.099 36.8315 108.299 35.957 107.697 34.7742C107.102 33.5842 106.805 32.0538 106.805 30.1828C106.805 28.2545 107.113 26.6667 107.73 25.4194C108.346 24.1649 109.153 23.2366 110.149 22.6344C111.153 22.0323 112.231 21.7312 113.385 21.7312C114.253 21.7312 114.995 21.8817 115.611 22.1828C116.235 22.4767 116.747 22.8566 117.149 23.3226C117.55 23.7885 117.855 24.2724 118.063 24.7742H118.213V21.9462H123.45V38.5269C123.45 39.9247 123.099 41.1039 122.396 42.0645C121.694 43.0251 120.712 43.7527 119.45 44.2473C118.188 44.7491 116.73 45 115.074 45ZM115.235 34.4086C115.873 34.4086 116.418 34.2401 116.869 33.9032C117.321 33.5663 117.669 33.0824 117.912 32.4516C118.156 31.8208 118.278 31.0645 118.278 30.1828C118.278 29.2867 118.156 28.5161 117.912 27.871C117.676 27.2186 117.328 26.7168 116.869 26.3656C116.418 26.0143 115.873 25.8387 115.235 25.8387C114.583 25.8387 114.031 26.0179 113.579 26.3763C113.127 26.7348 112.783 27.2401 112.547 27.8925C112.317 28.5376 112.203 29.3011 112.203 30.1828C112.203 31.0645 112.321 31.8208 112.558 32.4516C112.794 33.0824 113.135 33.5663 113.579 33.9032C114.031 34.2401 114.583 34.4086 115.235 34.4086Z"
        fill="white"
      />
      <path
        d="M129.944 38.7419C128.89 38.7419 127.955 38.5663 127.138 38.2151C126.328 37.8566 125.686 37.319 125.213 36.6021C124.747 35.8781 124.514 34.9713 124.514 33.8817C124.514 32.9642 124.675 32.19 124.998 31.5591C125.32 30.9283 125.765 30.4158 126.331 30.0215C126.897 29.6272 127.55 29.3297 128.288 29.129C129.027 28.9211 129.815 28.7814 130.654 28.7097C131.593 28.6237 132.349 28.5341 132.923 28.4409C133.496 28.3405 133.912 28.2007 134.17 28.0215C134.435 27.8351 134.568 27.5735 134.568 27.2366V27.1828C134.568 26.6308 134.378 26.2043 133.998 25.9032C133.618 25.6022 133.105 25.4516 132.46 25.4516C131.765 25.4516 131.206 25.6022 130.783 25.9032C130.36 26.2043 130.091 26.6201 129.976 27.1505L125.127 26.9785C125.27 25.9749 125.639 25.0789 126.234 24.2903C126.837 23.4946 127.661 22.871 128.708 22.4194C129.761 21.9606 131.027 21.7312 132.503 21.7312C133.557 21.7312 134.528 21.8566 135.417 22.1075C136.306 22.3513 137.08 22.7097 137.74 23.1828C138.399 23.6487 138.908 24.2222 139.267 24.9032C139.632 25.5842 139.815 26.362 139.815 27.2366V38.4624H134.869V36.1613H134.74C134.446 36.7204 134.07 37.1935 133.611 37.5806C133.159 37.9677 132.625 38.2581 132.009 38.4516C131.399 38.6452 130.711 38.7419 129.944 38.7419ZM131.568 35.3011C132.134 35.3011 132.643 35.1864 133.095 34.957C133.553 34.7276 133.919 34.4122 134.191 34.0108C134.464 33.6021 134.6 33.129 134.6 32.5914V31.0215C134.449 31.1004 134.267 31.172 134.052 31.2366C133.844 31.3011 133.614 31.362 133.363 31.4194C133.113 31.4767 132.854 31.5269 132.589 31.5699C132.324 31.6129 132.07 31.6523 131.826 31.6882C131.331 31.767 130.908 31.8889 130.557 32.0538C130.213 32.2186 129.948 32.4337 129.761 32.6989C129.582 32.957 129.492 33.2652 129.492 33.6237C129.492 34.1685 129.686 34.5842 130.073 34.871C130.467 35.1577 130.966 35.3011 131.568 35.3011Z"
        fill="white"
      />
      <path
        d="M146.774 29.043V38.4624H141.516V21.9462H146.516V24.9785H146.699C147.065 23.9677 147.688 23.1756 148.57 22.6022C149.452 22.0215 150.502 21.7312 151.72 21.7312C152.882 21.7312 153.889 21.9928 154.742 22.5161C155.602 23.0323 156.269 23.7563 156.742 24.6882C157.222 25.6129 157.459 26.6953 157.452 27.9355V38.4624H152.194V28.9677C152.201 28.0502 151.968 27.3333 151.495 26.8172C151.029 26.3011 150.38 26.043 149.548 26.043C148.996 26.043 148.509 26.1649 148.086 26.4086C147.67 26.6452 147.348 26.9857 147.118 27.4301C146.896 27.8746 146.781 28.4122 146.774 29.043Z"
        fill="white"
      />
      <path
        d="M163.935 34.129L163.956 27.8602H164.688L169.333 21.9462H175.29L168.387 30.4194H166.999L163.935 34.129ZM159.193 38.4624V16.4409H164.451V38.4624H159.193ZM169.451 38.4624L165.128 31.6021L168.591 27.871L175.526 38.4624H169.451Z"
        fill="white"
      />
      <path
        d="M182.698 38.7742C180.97 38.7742 179.479 38.4337 178.225 37.7527C176.977 37.0645 176.017 36.086 175.343 34.8172C174.676 33.5412 174.343 32.0251 174.343 30.2688C174.343 28.5627 174.68 27.0717 175.354 25.7957C176.028 24.5125 176.977 23.5161 178.203 22.8065C179.429 22.0896 180.873 21.7312 182.537 21.7312C183.712 21.7312 184.787 21.914 185.762 22.2796C186.737 22.6452 187.58 23.1864 188.289 23.9032C188.999 24.6201 189.551 25.5054 189.945 26.5591C190.339 27.6057 190.537 28.8065 190.537 30.1613V31.4731H176.182V28.4194H185.644C185.637 27.8602 185.504 27.362 185.246 26.9247C184.988 26.4875 184.633 26.147 184.182 25.9032C183.737 25.6523 183.225 25.5269 182.644 25.5269C182.056 25.5269 181.529 25.6595 181.063 25.9247C180.597 26.1828 180.228 26.5376 179.956 26.9892C179.684 27.4337 179.54 27.9391 179.526 28.5054V31.6129C179.526 32.2867 179.658 32.8781 179.924 33.3871C180.189 33.8889 180.565 34.2796 181.053 34.5591C181.54 34.8387 182.121 34.9785 182.795 34.9785C183.261 34.9785 183.684 34.914 184.063 34.7849C184.443 34.6559 184.77 34.4659 185.042 34.2151C185.314 33.9642 185.519 33.6559 185.655 33.2903L190.483 33.4301C190.282 34.5125 189.841 35.4552 189.16 36.2581C188.486 37.0538 187.601 37.6738 186.504 38.1183C185.408 38.5556 184.139 38.7742 182.698 38.7742Z"
        fill="white"
      />
      <path
        d="M196.966 16.4409V38.4624H191.708V16.4409H196.966Z"
        fill="white"
      />
      <path
        d="M198.799 38.4624V21.9462H204.057V38.4624H198.799ZM201.433 20.0215C200.695 20.0215 200.061 19.7778 199.53 19.2903C199 18.7957 198.734 18.2007 198.734 17.5054C198.734 16.8172 199 16.2294 199.53 15.7419C200.061 15.2473 200.695 15 201.433 15C202.179 15 202.813 15.2473 203.337 15.7419C203.867 16.2294 204.132 16.8172 204.132 17.5054C204.132 18.2007 203.867 18.7957 203.337 19.2903C202.813 19.7778 202.179 20.0215 201.433 20.0215Z"
        fill="white"
      />
      <path
        d="M205.89 21.9462H211.148V39.1505C211.148 40.5125 210.868 41.595 210.309 42.3978C209.757 43.2007 208.976 43.7778 207.965 44.129C206.954 44.4803 205.761 44.6559 204.384 44.6559C204.198 44.6559 204.022 44.6523 203.858 44.6452C203.693 44.638 203.513 44.6308 203.32 44.6237V40.6344C203.449 40.6487 203.564 40.6595 203.664 40.6667C203.764 40.6738 203.865 40.6774 203.965 40.6774C204.689 40.6774 205.191 40.5412 205.47 40.2688C205.75 40.0036 205.89 39.595 205.89 39.043V21.9462ZM208.513 20.0215C207.775 20.0215 207.141 19.7778 206.61 19.2903C206.08 18.7957 205.814 18.2007 205.814 17.5054C205.814 16.8172 206.08 16.2294 206.61 15.7419C207.141 15.2473 207.775 15 208.513 15C209.259 15 209.893 15.2473 210.417 15.7419C210.947 16.2294 211.212 16.8172 211.212 17.5054C211.212 18.2007 210.947 18.7957 210.417 19.2903C209.893 19.7778 209.259 20.0215 208.513 20.0215Z"
        fill="white"
      />
      <path
        d="M217.723 34.129L217.744 27.8602H218.475L223.12 21.9462H229.077L222.174 30.4194H220.787L217.723 34.129ZM212.981 38.4624V16.4409H218.239V38.4624H212.981ZM223.239 38.4624L218.916 31.6021L222.378 27.871L229.314 38.4624H223.239Z"
        fill="white"
      />
      <path
        d="M236.485 38.7742C234.758 38.7742 233.267 38.4337 232.012 37.7527C230.765 37.0645 229.804 36.086 229.131 34.8172C228.464 33.5412 228.131 32.0251 228.131 30.2688C228.131 28.5627 228.468 27.0717 229.141 25.7957C229.815 24.5125 230.765 23.5161 231.991 22.8065C233.217 22.0896 234.661 21.7312 236.324 21.7312C237.5 21.7312 238.575 21.914 239.55 22.2796C240.525 22.6452 241.367 23.1864 242.077 23.9032C242.787 24.6201 243.339 25.5054 243.733 26.5591C244.127 27.6057 244.324 28.8065 244.324 30.1613V31.4731H229.969V28.4194H239.432C239.425 27.8602 239.292 27.362 239.034 26.9247C238.776 26.4875 238.421 26.147 237.969 25.9032C237.525 25.6523 237.012 25.5269 236.432 25.5269C235.844 25.5269 235.317 25.6595 234.851 25.9247C234.385 26.1828 234.016 26.5376 233.744 26.9892C233.471 27.4337 233.328 27.9391 233.313 28.5054V31.6129C233.313 32.2867 233.446 32.8781 233.711 33.3871C233.977 33.8889 234.353 34.2796 234.84 34.5591C235.328 34.8387 235.908 34.9785 236.582 34.9785C237.048 34.9785 237.471 34.914 237.851 34.7849C238.231 34.6559 238.557 34.4659 238.83 34.2151C239.102 33.9642 239.306 33.6559 239.442 33.2903L244.27 33.4301C244.07 34.5125 243.629 35.4552 242.948 36.2581C242.274 37.0538 241.389 37.6738 240.292 38.1183C239.195 38.5556 237.926 38.7742 236.485 38.7742Z"
        fill="white"
      />
      <path
        d="M261.074 21.9462L255.407 38.4624H249.385L243.73 21.9462H249.267L252.31 33.3226H252.482L255.536 21.9462H261.074Z"
        fill="white"
      />
      <path
        d="M261.576 38.4624V21.9462H266.834V38.4624H261.576ZM264.21 20.0215C263.472 20.0215 262.837 19.7778 262.307 19.2903C261.777 18.7957 261.511 18.2007 261.511 17.5054C261.511 16.8172 261.777 16.2294 262.307 15.7419C262.837 15.2473 263.472 15 264.21 15C264.956 15 265.59 15.2473 266.113 15.7419C266.644 16.2294 266.909 16.8172 266.909 17.5054C266.909 18.2007 266.644 18.7957 266.113 19.2903C265.59 19.7778 264.956 20.0215 264.21 20.0215Z"
        fill="white"
      />
      <path
        d="M274.699 38.6989C273.48 38.6989 272.373 38.3835 271.376 37.7527C270.38 37.1219 269.584 36.1756 268.989 34.914C268.394 33.6523 268.097 32.086 268.097 30.2151C268.097 28.2724 268.405 26.6738 269.021 25.4194C269.638 24.1649 270.444 23.2366 271.441 22.6344C272.444 22.0323 273.523 21.7312 274.677 21.7312C275.545 21.7312 276.287 21.8817 276.903 22.1828C277.52 22.4767 278.029 22.8566 278.43 23.3226C278.832 23.7885 279.136 24.2724 279.344 24.7742H279.452V16.4409H284.71V38.4624H279.505V35.7849H279.344C279.122 36.2939 278.806 36.7706 278.398 37.2151C277.989 37.6595 277.477 38.0179 276.86 38.2903C276.251 38.5627 275.53 38.6989 274.699 38.6989ZM276.527 34.6021C277.165 34.6021 277.71 34.4229 278.161 34.0645C278.613 33.6989 278.961 33.1864 279.204 32.5269C279.448 31.8674 279.57 31.0932 279.57 30.2043C279.57 29.3011 279.448 28.5233 279.204 27.871C278.968 27.2186 278.62 26.7168 278.161 26.3656C277.71 26.0143 277.165 25.8387 276.527 25.8387C275.875 25.8387 275.323 26.0179 274.871 26.3763C274.419 26.7348 274.075 27.2401 273.839 27.8925C273.609 28.5448 273.495 29.3154 273.495 30.2043C273.495 31.0932 273.613 31.8674 273.849 32.5269C274.086 33.1864 274.426 33.6989 274.871 34.0645C275.323 34.4229 275.875 34.6021 276.527 34.6021Z"
        fill="white"
      />
      <path
        d="M294.241 38.7742C292.514 38.7742 291.023 38.4337 289.768 37.7527C288.521 37.0645 287.56 36.086 286.887 34.8172C286.22 33.5412 285.887 32.0251 285.887 30.2688C285.887 28.5627 286.223 27.0717 286.897 25.7957C287.571 24.5125 288.521 23.5161 289.747 22.8065C290.973 22.0896 292.417 21.7312 294.08 21.7312C295.256 21.7312 296.331 21.914 297.306 22.2796C298.281 22.6452 299.123 23.1864 299.833 23.9032C300.542 24.6201 301.094 25.5054 301.489 26.5591C301.883 27.6057 302.08 28.8065 302.08 30.1613V31.4731H287.725V28.4194H297.188C297.18 27.8602 297.048 27.362 296.79 26.9247C296.532 26.4875 296.177 26.147 295.725 25.9032C295.281 25.6523 294.768 25.5269 294.188 25.5269C293.6 25.5269 293.073 25.6595 292.607 25.9247C292.141 26.1828 291.772 26.5376 291.499 26.9892C291.227 27.4337 291.084 27.9391 291.069 28.5054V31.6129C291.069 32.2867 291.202 32.8781 291.467 33.3871C291.732 33.8889 292.109 34.2796 292.596 34.5591C293.084 34.8387 293.664 34.9785 294.338 34.9785C294.804 34.9785 295.227 34.914 295.607 34.7849C295.987 34.6559 296.313 34.4659 296.585 34.2151C296.858 33.9642 297.062 33.6559 297.198 33.2903L302.026 33.4301C301.826 34.5125 301.385 35.4552 300.704 36.2581C300.03 37.0538 299.145 37.6738 298.048 38.1183C296.951 38.5556 295.682 38.7742 294.241 38.7742Z"
        fill="white"
      />
      <path
        d="M310.961 38.7742C309.227 38.7742 307.735 38.4194 306.488 37.7097C305.248 36.9928 304.291 35.9964 303.617 34.7204C302.951 33.4373 302.617 31.9498 302.617 30.2581C302.617 28.5591 302.951 27.0717 303.617 25.7957C304.291 24.5125 305.248 23.5161 306.488 22.8065C307.735 22.0896 309.227 21.7312 310.961 21.7312C312.696 21.7312 314.184 22.0896 315.424 22.8065C316.671 23.5161 317.628 24.5125 318.295 25.7957C318.968 27.0717 319.305 28.5591 319.305 30.2581C319.305 31.9498 318.968 33.4373 318.295 34.7204C317.628 35.9964 316.671 36.9928 315.424 37.7097C314.184 38.4194 312.696 38.7742 310.961 38.7742ZM310.994 34.8064C311.624 34.8064 312.158 34.6129 312.596 34.2258C313.033 33.8387 313.366 33.3011 313.596 32.6129C313.832 31.9247 313.951 31.129 313.951 30.2258C313.951 29.3082 313.832 28.5054 313.596 27.8172C313.366 27.129 313.033 26.5914 312.596 26.2043C312.158 25.8172 311.624 25.6237 310.994 25.6237C310.341 25.6237 309.789 25.8172 309.338 26.2043C308.893 26.5914 308.553 27.129 308.316 27.8172C308.087 28.5054 307.972 29.3082 307.972 30.2258C307.972 31.129 308.087 31.9247 308.316 32.6129C308.553 33.3011 308.893 33.8387 309.338 34.2258C309.789 34.6129 310.341 34.8064 310.994 34.8064Z"
        fill="white"
      />
      <path
        d="M323.442 38.7742C322.668 38.7742 322.005 38.5018 321.453 37.957C320.908 37.4122 320.639 36.7527 320.646 35.9785C320.639 35.2186 320.908 34.5699 321.453 34.0323C322.005 33.4875 322.668 33.2151 323.442 33.2151C324.173 33.2151 324.818 33.4875 325.377 34.0323C325.944 34.5699 326.23 35.2186 326.238 35.9785C326.23 36.4946 326.094 36.9642 325.829 37.3871C325.571 37.81 325.23 38.147 324.807 38.3979C324.392 38.6487 323.937 38.7742 323.442 38.7742Z"
        fill="white"
      />
      <path
        d="M335.906 38.7742C334.172 38.7742 332.681 38.4194 331.433 37.7097C330.193 36.9928 329.236 35.9964 328.562 34.7204C327.896 33.4373 327.562 31.9498 327.562 30.2581C327.562 28.5591 327.896 27.0717 328.562 25.7957C329.236 24.5125 330.193 23.5161 331.433 22.8065C332.681 22.0896 334.172 21.7312 335.906 21.7312C337.641 21.7312 339.129 22.0896 340.369 22.8065C341.616 23.5161 342.573 24.5125 343.24 25.7957C343.914 27.0717 344.251 28.5591 344.251 30.2581C344.251 31.9498 343.914 33.4373 343.24 34.7204C342.573 35.9964 341.616 36.9928 340.369 37.7097C339.129 38.4194 337.641 38.7742 335.906 38.7742ZM335.939 34.8064C336.57 34.8064 337.104 34.6129 337.541 34.2258C337.978 33.8387 338.311 33.3011 338.541 32.6129C338.777 31.9247 338.896 31.129 338.896 30.2258C338.896 29.3082 338.777 28.5054 338.541 27.8172C338.311 27.129 337.978 26.5914 337.541 26.2043C337.104 25.8172 336.57 25.6237 335.939 25.6237C335.286 25.6237 334.734 25.8172 334.283 26.2043C333.838 26.5914 333.498 27.129 333.261 27.8172C333.032 28.5054 332.917 29.3082 332.917 30.2258C332.917 31.129 333.032 31.9247 333.261 32.6129C333.498 33.3011 333.838 33.8387 334.283 34.2258C334.734 34.6129 335.286 34.8064 335.939 34.8064Z"
        fill="white"
      />
      <path
        d="M345.43 38.4624V21.9462H350.538V24.957H350.71C351.011 23.8674 351.502 23.0573 352.183 22.5269C352.864 21.9892 353.656 21.7204 354.559 21.7204C354.803 21.7204 355.054 21.7384 355.312 21.7742C355.57 21.8029 355.81 21.8495 356.032 21.914V26.4839C355.781 26.3978 355.452 26.3297 355.043 26.2796C354.642 26.2294 354.283 26.2043 353.968 26.2043C353.344 26.2043 352.781 26.3441 352.28 26.6237C351.785 26.8961 351.394 27.2796 351.108 27.7742C350.828 28.2616 350.688 28.8351 350.688 29.4946V38.4624H345.43Z"
        fill="white"
      />
      <path
        d="M363.502 45C361.932 45 360.588 44.7814 359.47 44.3441C358.352 43.9068 357.473 43.3082 356.835 42.5484C356.197 41.7885 355.814 40.9283 355.685 39.9677L360.663 39.6882C360.757 40.0251 360.929 40.319 361.18 40.5699C361.438 40.8208 361.771 41.0143 362.18 41.1505C362.595 41.2867 363.083 41.3548 363.642 41.3548C364.524 41.3548 365.251 41.1398 365.825 40.7097C366.405 40.2867 366.696 39.5448 366.696 38.4839V35.5806H366.513C366.305 36.0896 365.99 36.5448 365.567 36.9462C365.144 37.3477 364.617 37.6631 363.986 37.8925C363.362 38.1219 362.646 38.2366 361.835 38.2366C360.631 38.2366 359.527 37.957 358.524 37.3979C357.527 36.8315 356.728 35.957 356.126 34.7742C355.531 33.5842 355.233 32.0538 355.233 30.1828C355.233 28.2545 355.542 26.6667 356.158 25.4194C356.775 24.1649 357.581 23.2366 358.577 22.6344C359.581 22.0323 360.66 21.7312 361.814 21.7312C362.681 21.7312 363.423 21.8817 364.04 22.1828C364.663 22.4767 365.176 22.8566 365.577 23.3226C365.979 23.7885 366.284 24.2724 366.491 24.7742H366.642V21.9462H371.878V38.5269C371.878 39.9247 371.527 41.1039 370.825 42.0645C370.122 43.0251 369.14 43.7527 367.878 44.2473C366.617 44.7491 365.158 45 363.502 45ZM363.663 34.4086C364.301 34.4086 364.846 34.2401 365.298 33.9032C365.749 33.5663 366.097 33.0824 366.341 32.4516C366.585 31.8208 366.706 31.0645 366.706 30.1828C366.706 29.2867 366.585 28.5161 366.341 27.871C366.104 27.2186 365.757 26.7168 365.298 26.3656C364.846 26.0143 364.301 25.8387 363.663 25.8387C363.011 25.8387 362.459 26.0179 362.008 26.3763C361.556 26.7348 361.212 27.2401 360.975 27.8925C360.746 28.5376 360.631 29.3011 360.631 30.1828C360.631 31.0645 360.749 31.8208 360.986 32.4516C361.223 33.0824 361.563 33.5663 362.008 33.9032C362.459 34.2401 363.011 34.4086 363.663 34.4086Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80585 0.921331C3.73121 0.337702 4.82722 0 6.00211 0C7.159 0 8.2394 0.327448 9.15572 0.894685L50.4794 24.7529C50.9573 25.0174 51.4023 25.349 51.7988 25.7435C52.3274 26.2682 52.7448 26.8795 53.0413 27.5406C53.3988 28.3336 53.5699 29.1715 53.5691 30.0003C53.5699 30.8299 53.3984 31.6689 53.04 32.4627C52.7445 33.1207 52.3291 33.7293 51.8034 34.2522C51.4057 34.6488 50.959 34.9819 50.4792 35.2476L9.06377 59.1587C8.78501 59.3245 8.49169 59.4682 8.18623 59.5877C5.46955 60.654 2.30838 59.6034 0.804816 56.9991C0.229335 56.0024 -0.0276296 54.9091 0.00234377 53.8372V6.16313C-0.0194864 5.38243 0.110933 4.59028 0.40575 3.83243C0.568967 3.41128 0.778357 3.01313 1.0277 2.64422C1.50677 1.93294 2.11651 1.35406 2.80585 0.921331ZM12.0019 16.3938V43.6066L35.5689 30.0002L12.0019 16.3938Z"
        fill="#00A7E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.18615 59.5878C7.5093 59.8524 6.77262 59.9977 6.00202 59.9977C2.68845 59.9977 0.0022583 57.3115 0.0022583 53.9979V53.8373C0.0585768 51.8232 1.12792 49.8846 3.0008 48.8033L12.0018 43.6066V53.9979C12.0018 56.1931 10.8229 58.1129 9.06369 59.1588L9.00055 59.1953C8.73513 59.3485 8.46297 59.4791 8.18615 59.5878Z"
        fill="#007DAE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.405665 3.83246C0.145147 4.50468 0.0022583 5.23557 0.0022583 5.99979V6.16316C0.0585735 8.17723 1.12792 10.1158 3.0008 11.1971L12.0018 16.3938V5.99979C12.0018 3.8431 10.8638 1.9522 9.15564 0.894714L9.00055 0.805183C6.25339 -0.780908 2.77325 0.052509 1.02762 2.64425C0.949792 2.75978 0.87544 2.87879 0.804732 3.00126C0.648409 3.27202 0.515616 3.54982 0.405665 3.83246Z"
        fill="#007DAE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4792 35.2476C48.6983 36.2333 46.4597 36.288 44.5685 35.1961L35.5688 30.0002L44.5685 24.8042C46.4598 23.7124 48.6984 23.7671 50.4794 24.7529L50.5683 24.8042C51.0278 25.0695 51.439 25.3865 51.7987 25.7435C52.1664 26.1094 52.4925 26.5296 52.7643 27.0003C52.8667 27.1776 52.9589 27.358 53.0413 27.5407C53.3872 28.3121 53.5684 29.1514 53.5691 30.0003C53.5683 30.8503 53.3867 31.6906 53.04 32.4627C52.958 32.6444 52.8661 32.8238 52.7643 33.0001C52.4937 33.469 52.1692 33.8874 51.8034 34.2522C51.4425 34.6112 51.0298 34.9297 50.5683 35.1961L50.4792 35.2476Z"
        fill="#007DAE"
      />
    </Svg>
  );
}

export function CC(props) {
  return (
    <Svg
      width={props.size}
      height={props.size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6749 8.45507C12.0742 9.2721 11.355 10.0978 10.4456 10.0978C10.0831 10.0978 9.78307 9.8324 9.64925 9.49545C9.19641 8.35527 8.10384 7.71278 6.76122 7.71278C4.65781 7.71278 3.176 9.30071 3.176 12.1553C3.176 15.0593 4.67036 16.5978 6.75494 16.5978C8.07513 16.5978 9.16872 15.9834 9.63885 14.8719C9.77881 14.541 10.0785 14.2821 10.4378 14.284C11.3507 14.2888 12.0602 15.123 11.6311 15.9287C10.7783 17.53 9.0486 18.6553 6.72355 18.6553C3.33297 18.6553 0.833984 16.2394 0.833984 12.1553C0.833984 8.06497 3.36436 5.65527 6.72355 5.65527C8.9623 5.65527 10.7983 6.66137 11.6749 8.45507ZM24.4881 8.78619C24.7562 9.45213 24.1921 10.0978 23.4742 10.0978C22.9019 10.0978 22.4382 9.66832 22.1603 9.16794C21.641 8.233 20.6354 7.71278 19.4272 7.71278C17.3238 7.71278 15.842 9.30071 15.842 12.1553C15.842 15.0593 17.3364 16.5978 19.421 16.5978C20.7411 16.5978 21.8347 15.9834 22.3049 14.8719C22.4448 14.541 22.7446 14.2821 23.1038 14.284C24.0167 14.2888 24.7262 15.123 24.2971 15.9287C23.4443 17.53 21.7146 18.6553 19.3896 18.6553C15.999 18.6553 13.5 16.2394 13.5 12.1553C13.5 8.06497 16.0304 5.65527 19.3896 5.65527C21.7626 5.65527 23.683 6.78564 24.4881 8.78619Z"
        fill={props.color}
      />
    </Svg>
  );
}

export function AD(props) {
  return (
    <Svg
      width={props.size}
      height={props.size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.20019 18.8135C2.63628 18.8135 3.02208 18.5309 3.15362 18.1151L3.9468 15.6079H8.56732L9.3657 18.1167C9.49775 18.5317 9.88315 18.8135 10.3186 18.8135H10.5764C11.2589 18.8135 11.7409 18.1448 11.5251 17.4973L8.06559 7.11685C7.80618 6.3385 7.0778 5.81348 6.25736 5.81348V5.81348C5.43662 5.81348 4.70805 6.33887 4.44888 7.11761L0.994315 17.4977C0.778841 18.1451 1.26079 18.8135 1.94315 18.8135H2.20019ZM4.54686 13.7163L6.20905 8.47949H6.30506L7.96725 13.7163H4.54686Z"
        fill={props.color}
      />
      <path
        d="M18.5917 18.8135C22.3362 18.8135 24.5564 16.3633 24.5564 12.3008C24.5564 8.25098 22.3362 5.81348 18.6758 5.81348H15.4273C14.875 5.81348 14.4273 6.26119 14.4273 6.81348V17.8135C14.4273 18.3658 14.875 18.8135 15.4273 18.8135H18.5917ZM16.6535 16.7759V7.85107H18.5497C21.046 7.85107 22.3482 9.32373 22.3482 12.3008C22.3482 15.2905 21.046 16.7759 18.4837 16.7759H16.6535Z"
        fill={props.color}
      />
    </Svg>
  );
}

const Icon = {
  propTypes: {
    size: PropTypes.number,
    color: PropTypes.string,
  },
  defaultProps: {
    size: 24,
    color: 'white',
  },
};
CC.propTypes = Icon.propTypes;
CC.defaultProps = Icon.defaultProps;
AD.propTypes = Icon.propTypes;
AD.defaultProps = Icon.defaultProps;

Logo.propTypes = {
  width: PropTypes.number,
};
