import styled from 'styled-components';

export const Slider = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 6px;
  border-radius: 2rem;
  background: ${(props) =>
    props.value
      ? `linear-gradient(to right, #21A5E2 0%, #21A5E2 ${
          (props.value / props.max) * 100
        }%, #C4C4C4 ${(props.value / props.max) * 100}%, #C4C4C4 100%);`
      : '#C4C4C4'};
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0.75rem;
    height: 0.75rem;
    background: ${(props) => props.theme.primary};
    border-radius: 50%;
    transition: 0.125s;
  }
  &:hover::-webkit-slider-thumb {
    background: ${(props) => props.theme.primaryHover};
    transform: scale(1.25);
    cursor: grab;
  }
  &:active::-webkit-slider-thumb {
    cursor: grabbing;
  }
  ::-moz-range-thumb {
    -moz-appearance: none;
    border: none;
    width: 1rem;
    height: 1rem;
    -moz-appearance: none;
    background: ${(props) => props.theme.primary};
    border-radius: 50%;
    transition: 0.125s;
  }
  &:hover::-moz-range-thumb {
    background: ${(props) => props.theme.primaryHover};
    transform: scale(1.25);
    cursor: grab;
  }
`;
