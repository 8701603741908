import React, { useRef, useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  Play,
  SkipBack,
  Pause,
  VolumeX,
  Volume2,
  Maximize,
} from 'react-feather';
import PropTypes from 'prop-types';

import {
  VideoProgressContainer,
  VideoProgress,
  VideoTime,
  Video,
  VideoContainer,
  ControlsContainer,
  ScreenReader,
  ControlsWrapper,
  VideoWrapper,
  PlayPause,
  CC,
  AD,
  Paragraph,
} from './../styles.jsx';
import { Slider } from './scrubber.jsx';
import VideoControlButton from './video-control-button.jsx';
import Details from './details.jsx';
import { Menu, MenuItem } from './menu.jsx';
import { Logo } from './../styles.jsx';

const styles = {
  Slider: styled(Slider)`
    width: 100%;
  `,
  ClosedCaptionsContainer: styled.div`
    position: relative;
  `,
  FreeVersionLogo: styled.a`
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;
  `,
};

export default function VideoPlayer(props) {
  const theme = useTheme();

  const videoRef = useRef(null);
  const audioRef = useRef(null);

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showSubtitle, setShowSubtitle] = useState(null);
  const [showSubtitleMenu, setShowSubtitleMenu] = useState(false);
  const [audioDescription, setAudioDescription] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isPaused, setIsPaused] = useState(true);

  const [showOneSubtitle, setShowOneSubtitle] = useState(false);

  const [video, setVideo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(props.url)
      .then((res) => res.json())
      .then((video) => setVideo(video))
      .catch((err) => setError(err));
  }, [props.url]);

  useEffect(() => {
    if (video && props.handleTheme) {
      props.handleTheme(video.darkMode);
    }
  }, [video]);

  const handleAudioDescription = () => {
    if (audioDescription) {
      audioRef.current.pause();
      setAudioDescription(false);
    } else if (!playing) {
      audioRef.current.currentTime = currentTime;
      setAudioDescription(true);
    } else {
      audioRef.current.currentTime = currentTime;
      audioRef.current.play();
      setAudioDescription(true);
    }
  };

  function setInitialDuration(event) {
    setDuration(event.target.duration);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current) {
        setCurrentTime(videoRef.current.currentTime);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [playing]);

  const handleScrubTime = (event) => {
    if (!duration) return;
    setCurrentTime(event.target.value);
    videoRef.current.currentTime = currentTime;
    audioRef.current.currentTime = currentTime;
  };

  const restartVideo = () => {
    videoRef.current.currentTime = 0;
    audioRef.current.currentTime = 0;
  };

  const handlePlayPauseVideo = () => {
    if (!playing && !audioDescription) {
      videoRef.current.play();
    } else if (!playing && audioDescription) {
      videoRef.current.play();
      audioRef.current.play();
    } else {
      videoRef.current.pause();
      audioRef.current.pause();
    }
    setPlaying(!playing);
    setDuration(videoRef.current.duration);
  };

  const handleMuteVideo = () => {
    setMuted(!muted);
    videoRef.current.muted = !muted;
    videoRef.current.defaultMuted = !muted;
  };

  const handleVolume = (event) => {
    setVolume(event.target.value);
    videoRef.current.volume = volume;
  };

  const openFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      /* Safari */
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      /* IE11 */
      videoRef.current.msRequestFullscreen();
    }
  };

  if (!video && !error) {
    return (
      <VideoContainer>
        <VideoWrapper>
          <p>Laden...</p>
        </VideoWrapper>
      </VideoContainer>
    );
  }

  if (!video) {
    return null;
  }

  if (error) {
    return (
      <VideoContainer>
        <VideoWrapper>
          <p>Kon video niet laden</p>
        </VideoWrapper>
      </VideoContainer>
    );
  }

  if (error) {
    return <Paragraph>De video kan niet worden geladen...</Paragraph>;
  }

  const setSubtitle = (subtitle) => {
    setShowSubtitle(subtitle !== null ? subtitle.videoSubtitle.language : null);
    setShowSubtitleMenu(false);
    const textTracks = videoRef.current.textTracks;
    for (let i = 0; i < textTracks.length; i++) {
      if (subtitle == null) {
        videoRef.current.textTracks[i].mode = 'disabled';
        return;
      }
      subtitle.videoSubtitle.language === textTracks[i].language
        ? (videoRef.current.textTracks[i].mode = 'showing')
        : (videoRef.current.textTracks[i].mode = 'disabled');
    }
  };

  return (
    <VideoContainer>
      <VideoWrapper>
        {video?.freeVersion && isPaused ? (
          <styles.FreeVersionLogo
            href="https://toegankelijkevideo.org/"
            target="_blank"
            tabIndex={'-1'}
          >
            <Logo width={164} />
          </styles.FreeVersionLogo>
        ) : (
          <></>
        )}

        <Video
          id="video"
          ref={videoRef}
          default
          preload="auto"
          poster={video.poster.url}
          onPlay={() => setIsPaused(false)}
          onPause={() => setIsPaused(true)}
          onClick={() => handlePlayPauseVideo()}
          onLoadedMetadata={setInitialDuration}
          crossOrigin="anonymous"
        >
          <source src={video.videoFile.url} />

          {video &&
            video?.subtitles &&
            video?.subtitles.map((subtitle) => {
              return (
                <track
                  key={subtitle.id}
                  mode="disabled"
                  kind="captions"
                  label={subtitle.slug}
                  src={subtitle.url}
                  srcLang={subtitle.videoSubtitle.language}
                ></track>
              );
            })}

          {showOneSubtitle ? (
            <track
              kind="captions"
              label="Captions"
              src={video?.subtitle?.url}
              srcLang="en"
              default
            ></track>
          ) : (
            <></>
          )}
        </Video>
        {!playing && (
          <PlayPause onClick={() => handlePlayPauseVideo()}>
            <ScreenReader className="sr-only">Video afspelen</ScreenReader>
            <Play size={64} color="white" />
          </PlayPause>
        )}
      </VideoWrapper>
      <audio ref={audioRef}>
        <source src={video?.closedCaption?.url} />
      </audio>
      <VideoProgressContainer>
        <VideoTime>
          {currentTime < 3600
            ? new Date(currentTime * 1000).toISOString().substr(14, 5)
            : new Date(currentTime * 1000).toISOString().substr(11, 8)}
        </VideoTime>
        <VideoProgress>
          <Slider
            onChange={(event) => handleScrubTime(event)}
            onMouseUp={(event) => handleScrubTime(event)}
            id="seek"
            value={Math.floor(currentTime)}
            min="0"
            max={duration}
            type="range"
            aria-label="Video voortgang tijd"
          />
        </VideoProgress>
      </VideoProgressContainer>
      <ControlsContainer>
        <ControlsWrapper>
          <VideoControlButton
            id="skipBack"
            onClick={() => restartVideo()}
            icon={<SkipBack />}
            tooltip="Herstarten"
            screenreader="Video herstarten"
          />
          {!playing ? (
            <VideoControlButton
              id="playVideo"
              onClick={() => handlePlayPauseVideo()}
              icon={<Play />}
              tooltip="Afspelen"
              screenreader="Video afspelen"
            />
          ) : (
            <VideoControlButton
              id="pauseVideo"
              onClick={() => handlePlayPauseVideo()}
              icon={<Pause />}
              tooltip="Pauzeren"
              screenreader="Video pauzeren"
            />
          )}

          {muted ? (
            <VideoControlButton
              id="volumeOff"
              onClick={() => handleMuteVideo()}
              icon={<VolumeX />}
              tooltip="Geluid uit"
              screenreader="Geluid aanzetten"
            />
          ) : (
            <VideoControlButton
              onMouseLeave={() => setIsHovering(!isHovering)}
              id="volumeOn"
              onClick={() => handleMuteVideo()}
              icon={<Volume2 />}
              tooltip="Geluid aan"
              screenreader="Geluid uitzetten"
            />
          )}
          <ScreenReader className="sr-only" htmlFor="volumeslider">
            Volume: {volume}
          </ScreenReader>
          {!isHovering && (
            <styles.Slider
              onChange={(event) => handleVolume(event)}
              id="volumeslider"
              type="range"
              min="0"
              max="1"
              value={volume}
              step="0.1"
              aria-label="Volume"
            />
          )}
        </ControlsWrapper>

        <ControlsWrapper>
          {video && video?.subtitles && video?.subtitles.length > 0 ? (
            <styles.ClosedCaptionsContainer>
              <VideoControlButton
                id="closedCaptions"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSubtitleMenu(!showSubtitleMenu);
                }}
                icon={<CC color={theme.contrast} size={24} />}
                tooltip="Ondertiteling"
                screenreader={
                  showSubtitle
                    ? 'Ondertiteling uitzetten'
                    : 'Ondertiteling aanzetten'
                }
                active={showSubtitle !== null}
              />
              {showSubtitleMenu && (
                <Menu color={theme.contrast}>
                  <MenuItem
                    tabIndex={'0'}
                    selected={showSubtitle == null}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setSubtitle(null);
                      }
                    }}
                    onClick={() => setSubtitle(null)}
                  >
                    Uit
                  </MenuItem>
                  {video.subtitles.map((subtitle) => (
                    <MenuItem
                      tabIndex={'0'}
                      selected={subtitle.videoSubtitle.language == showSubtitle}
                      key={subtitle.id}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setSubtitle(subtitle);
                        }
                      }}
                      onClick={() => {
                        setSubtitle(subtitle);
                      }}
                    >
                      {subtitle.slug}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </styles.ClosedCaptionsContainer>
          ) : (
            <></>
          )}

          {video && video?.subtitle ? (
            <VideoControlButton
              id="closedCaptions"
              onClick={() => setShowOneSubtitle(!showOneSubtitle)}
              icon={<CC color={theme.contrast} size={24} />}
              tooltip="Ondertiteling"
              screenreader={
                showSubtitle
                  ? 'Ondertiteling uitzetten'
                  : 'Ondertiteling aanzetten'
              }
              active={showOneSubtitle}
            />
          ) : (
            <></>
          )}

          <VideoControlButton
            id="audioDescriptie"
            onClick={() => handleAudioDescription()}
            icon={<AD color={theme.contrast} size={24} />}
            tooltip="Audiodescriptie"
            screenreader={
              audioDescription
                ? 'Audiodescriptie uitzetten'
                : 'Audiodescriptie aanzetten'
            }
            active={audioDescription}
          />

          <VideoControlButton
            id="fullscreen"
            onClick={() => openFullscreen()}
            icon={<Maximize />}
            tooltip="Volledig scherm"
            screenreader="Video naar volledig scherm"
          />
        </ControlsWrapper>
      </ControlsContainer>
      {video.writtenText ? (
        <Details
          id="transscript"
          title="Uitgeschreven tekst"
          content={video?.writtenText?.split(/\n/).map((slice) => (
            <>
              {slice} <br></br>
            </>
          ))}
        />
      ) : null}
    </VideoContainer>
  );
}

VideoPlayer.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string.isRequired,
  handleTheme: PropTypes.func,
};
