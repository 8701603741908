import styled from 'styled-components';
import { DarkTheme } from '../theme/colors';

export const Menu = styled.div`
  position: absolute;
  right: 3rem;
  bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: ${DarkTheme.cardBackground};
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 12px ${DarkTheme.contrastLow};
  z-index: 10;
`;

export const MenuItem = styled.a`
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.225s;
  font-size: 0.75rem;
  text-decoration: none;
  color: ${(props) => (props.danger ? props.theme.dangerTextColor : 'white')};
  background-color: ${(props) =>
    props.selected ? DarkTheme.contrastOpac15 : 'none'};
  &:hover {
    background-color: ${DarkTheme.contrastOpac15};
    cursor: pointer;
  }
`;
