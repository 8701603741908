import React from 'react';
import ReactDOM from 'react-dom';

import { VideoPlayer } from './index.jsx';

window.addEventListener('DOMContentLoaded', () => {
  if (!window.videoPlayerScriptLoaded) {
    window.videoPlayerScriptLoaded = true;

    const videos = document.querySelectorAll('[data-sv-url]');
    videos.forEach((el) => {
      ReactDOM.render(<VideoPlayer url={el.dataset.svUrl} />, el);
    });
  }
});
