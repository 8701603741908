import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { ReactTooltipStyled, ScreenReader, VideoButton } from '../styles.jsx';

function VideoControlButton({ tooltip, icon, screenreader, onClick, active }) {
  const theme = useTheme();

  return (
    <a data-tip data-for={tooltip}>
      <ReactTooltipStyled
        id={tooltip}
        place="top"
        type={theme.name === 'LightTheme' ? 'dark' : 'light'}
        effect="solid"
        delayShow={500}
      >
        <span>{tooltip}</span>
      </ReactTooltipStyled>
      <VideoButton active={active} onClick={onClick}>
        {icon}
        <ScreenReader className="sr-only">{screenreader}</ScreenReader>
      </VideoButton>
    </a>
  );
}

VideoControlButton.propTypes = {
  tooltip: PropTypes.node.isRequired,
  icon: PropTypes.element.isRequired,
  screenreader: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default VideoControlButton;
